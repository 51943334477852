.fab-wrapper {
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 333;

  @include mq(xs, max) {
    right: 50%;
    transform: translateX(50%);
    bottom: 16px;
  }

  > button:nth-child(1n + 1) {
    margin-left: 8px;
  }
}
