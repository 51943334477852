@import '../../variables';

$particles: 30;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();

/*
if not working check migration
Deprecation math.random() will no longer ignore $limit units (360%) in a future release.
Recommendation: math.random(math.div($limit, 1%)) * 1%
To preserve current behavior: math.random(math.div($limit, 1%))
More info: https://sass-lang.com/d/function-units
 */

@for $i from 0 through $particles {
  $box-shadow:
    $box-shadow,
    math.floor(math.random() * $width) -
      $width *
      0.5 +
      px
      math.floor(math.random() * $height) -
      $height *
      0.8333 +
      px
      hsl(math.floor(math.random() * 360), 100%, 50%);
  $box-shadow2:
    $box-shadow2,
    0 0 #fff;
}

.pyro {
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation-delay: 5s;
  animation: 0.4s fadeIn ease-in forwards;

  > .pyro-before,
  > .pyro-after {
    position: absolute;
    width: 5px;
    height: 5px;
    //border-radius: 50%;
    box-shadow: $box-shadow2;
    animation:
      1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards;
    opacity: 0;
  }

  > .pyro-after {
    animation-delay: 1.25s, 1.25s, 1.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
  }
}

@keyframes bang {
  to {
    box-shadow: $box-shadow;
  }
}

@keyframes gravity {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  100% {
    transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  1% {
    visibility: visible;
  }
  100% {
    opacity: 1;
  }
}
