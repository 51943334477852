@use 'sass:math';

@import '../node_modules/@fontsource/roboto/400.css';
@import '../node_modules/@fontsource/roboto/400-italic.css';
@import '../node_modules/@fontsource/roboto/700.css';
@import '../node_modules/@fontsource/roboto/300.css';
@import './styles/font/material-icons';

@import '_variables';
@import './styles/themes';
@import './styles/page';
@import './styles/util';
@import './styles/components/components';

* {
  //box-shadow: none !important;
  user-select: none;
  -webkit-touch-callout: none;
}

p,
input,
textarea,
pre,
[contenteditable] {
  user-select: text;
  -webkit-touch-callout: default;
}

body.isDisableAnimations {
  *,
  *:after,
  *:before {
    transition: none !important;
    animation: none !important;
  }
}
