@import '../../variables';

// to share between planner-task and planner-calendar-event
.planner-time-remaining-shared {
  min-width: 44px;
  text-align: right;
  //white-space: nowrap;
  padding-left: 4px;
  padding-right: 8px;
  font-style: italic;
  font-weight: 300;
  line-height: 1;
  opacity: 0.6;
  margin-left: auto;
  font-size: $planner-font-size-mobile;

  @include mq(xs) {
    font-size: $planner-font-size;
  }

  mat-icon {
    vertical-align: middle;
    margin-top: -2px;
    height: 14px !important;

    @include mq(xs) {
      height: 16px !important;
      font-size: $planner-font-size;
    }
  }
}

.planner-drag-place-holder-shared {
  min-height: $planner-item-height;
  margin-bottom: 4px;
  border-radius: 6px;
  // NOTE bg color is set in material overwrite file since otherwise it does not seem to work
  background: rgba(0, 0, 0, 0.05);

  @include darkTheme(true) {
    background: rgba(255, 255, 255, 0.05) !important;
  }
}
