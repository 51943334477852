@import '../../variables';

$owl-accent-color: $c-primary;
$owl-accent-contrast-color: $c-contrast;
$owl-bg: transparent;
$owl-shadow: none;
$owl-today-not-selected: $c-primary;

// dark theme
$owl-dark-text-color-strong: rgba(255, 255, 255, 0.9);
$owl-dark-text-color: rgba(255, 255, 255, 0.75);
$owl-dark-text-color-less-intense: rgba(255, 255, 255, 0.5);
$owl-dark-text-color-muted: rgba(255, 255, 255, 0.15);

$owl-dark-light-selected-bg: rgba(49, 49, 49, 1);

$owl-dark-divider-color: rgba(255, 255, 255, 0.12);

$owl-dark-inp-bg-color: rgba(255, 255, 255, 0.08);
$owl-dark-inp-fg-color: $owl-dark-text-color-strong;
$owl-dark-arrow-color: $owl-dark-text-color-strong;

// light (default) theme
$owl-text-color-strong: rgba(0, 0, 0, 0.9);
$owl-text-color: rgba(0, 0, 0, 0.75);
$owl-text-color-less-intense: rgba(0, 0, 0, 0.5);
$owl-text-color-muted: rgba(0, 0, 0, 0.15);

$owl-light-selected-bg: rgb(238, 238, 238);

$owl-divider-color: rgba(0, 0, 0, 0.12);

$owl-inp-bg-color: rgba(0, 0, 0, 0.04);

$owl-inp-fg-color: $owl-text-color-strong;
$owl-arrow-color: $owl-text-color-strong;

$owl-missing: $owl-light-selected-bg !default;

.owl-dialog-container {
  position: relative;
  pointer-events: auto;
  box-sizing: border-box;
  display: block;
  padding: 1.5em;
  box-shadow: $owl-shadow;
  border-radius: 2px;
  overflow: auto;
  background: $owl-bg;
  width: 100%;
  height: 100%;
  outline: none;
  color: $owl-text-color;

  @include darkTheme(true) {
    color: $owl-dark-text-color;
  }
}

.owl-dt-container,
.owl-dt-container * {
  box-sizing: border-box;
}

.owl-dt-container {
  display: block;
  font-size: 1rem;
  background: $owl-bg;
  pointer-events: auto;
  z-index: 1000;
}

.owl-dt-container-row {
  border-bottom: 1px solid $owl-divider-color;

  @include darkTheme(true) {
    border-color: $owl-dark-divider-color;
  }

  &:last-child {
    border-bottom: none;
  }
}

.owl-dt-calendar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
}

.owl-dt-calendar-control {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 1em;
  width: 100%;
  padding: 0.5em;
  color: $owl-text-color-strong;

  @include darkTheme(true) {
    color: $owl-dark-text-color-strong;
  }

  .owl-dt-calendar-control-content {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;

    .owl-dt-calendar-control-button {
      padding: 0 0.8em;

      &:hover {
        background-color: $owl-missing;
      }
    }
  }
}

.owl-dt-calendar-main {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 0 0.2em 0.2em;
  outline: 0;
}

.owl-dt-calendar-view {
  display: block;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.owl-dt-calendar-multi-year-view {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  .owl-dt-calendar-table {
    width: calc(100% - 3em);

    .owl-dt-calendar-header th {
      padding-bottom: 0.25em;
    }
  }
}

.owl-dt-calendar-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  .owl-dt-calendar-header {
    color: $owl-text-color-less-intense;
    border: 0;

    @include darkTheme(true) {
      color: $owl-dark-text-color-less-intense;
    }

    .owl-dt-weekdays {
      th {
        font-size: 0.7em;
        font-weight: normal;
        text-align: center;
        padding-bottom: 0;
      }
    }

    .owl-dt-calendar-table-divider {
      position: relative;
      height: 1px;
      padding-bottom: 0.5em;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: $owl-divider-color;

        @include darkTheme(true) {
          background: $owl-dark-divider-color;
        }
      }
    }
  }

  .owl-dt-calendar-cell {
    position: relative;
    height: 0;
    line-height: 0;
    text-align: center;
    outline: 0;
    color: $owl-text-color;
    -webkit-appearance: none;
    padding-top: 1em;
    padding-bottom: 1em;
    -webkit-tap-highlight-color: transparent;

    @include darkTheme(true) {
      color: $owl-dark-text-color;
    }
  }

  .owl-dt-calendar-cell-content {
    position: absolute;
    top: 5%;
    left: 5%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    font-size: 0.8em;
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 999px;
    color: inherit;
    cursor: pointer;
  }

  .owl-dt-calendar-cell-out {
    opacity: 0.3;
  }

  .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    border-color: $owl-today-not-selected;
  }

  .owl-dt-calendar-cell-selected {
    color: $owl-accent-contrast-color;
    background-color: $owl-accent-color;

    &.owl-dt-calendar-cell-today {
      box-shadow: inset 0 0 0 2px hsla(0, 0%, 100%, 0.85);
    }
  }

  .owl-dt-calendar-cell-disabled {
    cursor: default;

    & > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
      color: $owl-text-color-muted;

      @include darkTheme(true) {
        color: $owl-dark-text-color-muted;
      }
    }

    & > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
      opacity: 0.4;
    }

    & > .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
      border-color: $owl-missing;
    }
  }

  .owl-dt-calendar-cell-active:focus
    > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
  :not(.owl-dt-calendar-cell-disabled):hover
    > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
    background-color: $owl-light-selected-bg;

    @include darkTheme(true) {
      background-color: $owl-dark-light-selected-bg;
    }
  }

  .owl-dt-calendar-cell-in-range {
    background: $owl-missing;

    &.owl-dt-calendar-cell-range-from {
      border-top-left-radius: 999px;
      border-bottom-left-radius: 999px;
    }

    &.owl-dt-calendar-cell-range-to {
      border-top-right-radius: 999px;
      border-bottom-right-radius: 999px;
    }
  }
}

.owl-dt-timer {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  padding: 0.5em;
  height: auto;
  outline: none;
}

.owl-dt-timer-box {
  position: relative;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.owl-dt-timer-content {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin: 0.2em 0;

  .owl-dt-timer-input {
    display: block;
    width: 2em;
    text-align: center;
    border: 1px solid $owl-divider-color;
    color: $owl-inp-fg-color;
    border-radius: 3px;
    outline: medium none;
    font-size: 1.2em;
    padding: 0.2em;
    background: $owl-inp-bg-color;

    @include darkTheme(true) {
      border-color: $owl-dark-divider-color;
      color: $owl-dark-inp-fg-color;
      background: $owl-dark-inp-bg-color;
    }
  }
}

.owl-dt-timer-divider {
  display: inline-block;
  align-self: flex-end;
  position: absolute;
  width: 0.6em;
  color: $owl-text-color-strong;
  height: 100%;
  left: -0.3em;

  @include darkTheme(true) {
    color: $owl-dark-text-color-strong;
  }

  &:before,
  &:after {
    content: '';
    display: inline-block;
    width: 0.35em;
    height: 0.35em;
    position: absolute;
    left: 50%;
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: currentColor;
  }

  &:before {
    top: 35%;
  }

  &:after {
    bottom: 35%;
  }
}

.owl-dt-control-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-size: 1em;
  color: inherit;

  .owl-dt-control-button-content {
    position: relative;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    outline: none;
  }
}

.owl-dt-control-period-button {
  .owl-dt-control-button-content {
    height: 1.5em;
    padding: 0 0.5em;
    border-radius: 3px;
    -webkit-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
  }

  &:hover > .owl-dt-control-button-content {
    background-color: $owl-light-selected-bg;

    @include darkTheme(true) {
      background-color: $owl-dark-light-selected-bg;
    }
  }

  .owl-dt-control-button-arrow {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 1em;
    height: 1em;
    margin: 0.1em;
    transition: transform 200ms ease;
    color: $owl-arrow-color;
    fill: $owl-arrow-color;

    @include darkTheme(true) {
      color: $owl-dark-arrow-color;
      fill: $owl-dark-arrow-color;
    }
  }
}

.owl-dt-control-arrow-button {
  color: $owl-arrow-color;

  @include darkTheme(true) {
    color: $owl-dark-arrow-color;
  }

  .owl-dt-control-button-content {
    padding: 0;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
  }

  &[disabled] {
    cursor: default;
    color: $owl-text-color-muted;

    @include darkTheme(true) {
      color: $owl-dark-text-color-muted;
    }
  }

  svg {
    width: 50%;
    height: 50%;
    fill: currentColor;
  }
}

.owl-dt-inline-container,
.owl-dt-popup-container {
  position: relative;
  box-shadow: $owl-shadow;

  .owl-dt-calendar,
  .owl-dt-timer {
    width: 100%;
  }

  .owl-dt-calendar {
    height: 20.25em;
  }
}

.owl-dt-inline-container {
  .owl-dt-calendar {
    height: auto;
  }
}

.owl-dt-dialog-container {
  max-height: 95vh;
  margin: -1.5em;

  .owl-dt-calendar {
    min-width: 250px;
    max-width: 750px;
    max-height: 750px;
  }

  .owl-dt-timer {
    max-width: 750px;
  }
}

@media all and (orientation: landscape) {
  .owl-dt-dialog-container {
    .owl-dt-calendar {
      width: 58vh;
      height: 62vh;
    }

    .owl-dt-timer {
      width: 58vh;
    }
  }
}

@media all and (orientation: portrait) {
  .owl-dt-dialog-container {
  }
}

.owl-dt-container-buttons {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 2em;
  color: $owl-accent-color;
}

.owl-dt-container-control-button {
  font-size: 1em;
  width: 50%;
  height: 100%;
  border-radius: 0;

  .owl-dt-control-button-content {
    height: 100%;
    width: 100%;
    -webkit-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
  }

  &:hover {
    .owl-dt-control-button-content {
      background-color: $owl-missing;
    }
  }
}

.owl-dt-container-info {
  padding: 0 0.5em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .owl-dt-container-range {
    outline: none;

    .owl-dt-container-range-content {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding: 0.5em 0;
      font-size: 0.8em;
    }

    &:last-child {
      border-top: 1px solid $owl-divider-color;

      @include darkTheme(true) {
        border-color: $owl-dark-divider-color;
      }
    }
  }

  .owl-dt-container-info-active {
    color: $owl-accent-color;
  }
}

.owl-dt-container-disabled,
.owl-dt-trigger-disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important;
}

.owl-dt-timer-hour12 {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: $owl-accent-color;

  .owl-dt-timer-hour12-box {
    border: 1px solid currentColor;
    border-radius: 2px;
    -webkit-transition: background 200ms ease;
    transition: background 200ms ease;

    .owl-dt-control-button-content {
      width: 100%;
      height: 100%;
      padding: 0.5em;
    }

    &:focus,
    &:hover {
      .owl-dt-control-button-content {
        background: $owl-accent-color;
        color: $owl-accent-contrast-color;
      }
    }
  }
}

.owl-dt-calendar-only-current-month {
  .owl-dt-calendar-cell-out {
    visibility: hidden;
    cursor: default;
  }
}

.owl-dt-inline {
  display: block;
}

.owl-dt-control {
  outline: none;
  cursor: pointer;

  .owl-dt-control-content {
    outline: none;
  }

  &:focus > .owl-dt-control-content {
    background-color: $owl-missing;
  }

  &:not(:-moz-focusring):focus > .owl-dt-control-content {
    box-shadow: none;
  }
}

.owl-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.owl-header-wrapper {
  display: flex;
  border-bottom: 1px solid $owl-divider-color;
  align-items: stretch;

  @include darkTheme(true) {
    border-color: $owl-dark-divider-color;
  }

  > * {
    flex: 1;

    &:first-child {
      border-right: 1px solid $owl-divider-color;

      @include darkTheme(true) {
        border-color: $owl-dark-divider-color;
      }
    }
  }
}

.owl-dt-schedule {
  display: flex;
  flex-direction: column;
}

.owl-dt-schedule-item {
  flex: 1;
  padding: 0 0.5em;
  font-size: 0.9em;
  background: transparent;
  border: none;
  cursor: pointer;
  width: auto;
  display: flex;
  align-items: center;
  min-height: 36px;
  border-left: 4px solid transparent;
  color: $owl-text-color-strong;
  opacity: 0.9;
  text-align: left;

  @include mq(xs, max) {
    padding: 0;
    min-height: 44px;
  }

  @include darkTheme(true) {
    color: $owl-dark-text-color-strong;
  }

  &:hover {
    outline: none;
    background: $owl-light-selected-bg;
    opacity: 1;

    @include darkTheme(true) {
      background: $owl-dark-light-selected-bg;
    }
  }

  &:focus {
    border-color: $c-primary !important;
    outline: none;
    background: $owl-light-selected-bg;
    opacity: 1;

    @include darkTheme(true) {
      background: $owl-dark-light-selected-bg;
    }
  }

  &.isActive {
    background: $c-primary !important;
    color: $c-contrast;
    outline: none;
    border-color: $owl-light-selected-bg;
    @include darkTheme(true) {
      border-color: $owl-dark-light-selected-bg;
    }
  }
}

$ico-size: 24px;
$ico-size-real: 20px;
.owl-dt-schedule-item--icon {
  margin-right: $s * 0.5;
  opacity: 0.85;
  width: $ico-size;
  height: $ico-size;
  line-height: $ico-size;
  text-align: center;
  position: relative;
  font-size: 0;

  @include mq(xs, max) {
    margin-left: $s;
    margin-right: $s;
  }

  &:after {
    font-size: $ico-size-real;
    font-family: 'Material Icons';
    content: 'link';
    -webkit-font-feature-settings: 'liga';

    .owl-dt-schedule-item:first-of-type & {
      content: 'wb_sunny';
    }

    .owl-dt-schedule-item:nth-of-type(2) & {
      content: 'event';
    }

    .owl-dt-schedule-item:last-of-type & {
      content: 'event';
    }
  }
}

.owl-top-input {
  border-bottom: 1px solid $owl-divider-color;

  @include darkTheme(true) {
    border-color: $owl-dark-divider-color;
  }

  input {
    border: none;
    width: 100%;
    height: 36px;
    line-height: 36px;
    padding: 0 0.5em;
    color: $owl-inp-fg-color;
    background: $owl-inp-bg-color;

    @include darkTheme(true) {
      color: $owl-dark-inp-fg-color;
      background: $owl-dark-inp-bg-color;
    }
  }
}

.owl-enter-message {
  position: absolute;
  top: $s * 0.5;
  left: 50%;
  max-width: 96%;
  width: 280px;
  font-size: 1em;
  padding: 0.25em 1em;
  transform: translate(-50%, 0);
  border-radius: 4px;
  z-index: 999;
  text-align: center;
  color: $owl-text-color-strong;
  background: $owl-light-selected-bg;

  @include darkTheme(true) {
    color: $owl-dark-text-color-strong;
    background: $owl-dark-light-selected-bg;
  }
}

.owl-wrap-cal {
  position: relative;
}

.owl-mobile-time-inp {
  border: none;
  text-align: center;
  height: 100%;
  font-size: 1.3em;
  font-family: inherit;
  color: $owl-text-color-strong;
  background: transparent;
  -webkit-appearance: none;
  background: none;
  // NOTE: center for android
  width: auto;

  @include darkTheme(true) {
    color: $owl-dark-text-color-strong;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-clear-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
  font-weight: bold;
}

.owl-dt-calendar-cell-active:focus > {
  .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
    color: $owl-text-color-strong;
    background: $owl-light-selected-bg;

    &.owl-dt-calendar-cell-today {
      border-color: $owl-accent-color;
    }

    @include darkTheme(true) {
      color: $owl-dark-text-color-strong;
      background: $owl-dark-light-selected-bg;
    }
  }
}
