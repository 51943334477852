@use 'angular-material-css-vars' as mat-css-vars;
@import '../_variables.scss';

html,
body,
app-root,
.app-frame {
  overflow: hidden;
  margin: 0;
  height: 100%;
  height: -webkit-fill-available;
  box-sizing: border-box;
  //font-family: 'Montserrat', 'Roboto', sans-serif;
  //font-family: 'Open Sans', sans-serif;
  //font-family: 'Roboto', sans-serif;
  font-family: 'Roboto', 'Comic Sans MS';
}

html {
  overflow: hidden !important;
}

html {
  button,
  a {
    -webkit-app-region: no-drag !important;
  }
}

body {
  // to fix older android web view
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  &:before {
    display: none;
    transition: 1s opacity;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
  }

  &.isEnabledBackgroundGradient {
    &:before {
      display: block;
    }
  }

  &.isLightTheme {
    color: $light-theme-text-color;
    background: $light-theme-bg;

    &.isDisableBackgroundGradient {
      background: $light-theme-bg;
    }

    &:before {
      opacity: 1;
      filter: saturate(0.8);
      background: linear-gradient(
        180deg,
        mat-css-vars.mat-css-color(100, 1) 0%,
        mat-css-vars.mat-css-color(50, 1) 140px,
        $light-theme-bg 100%
      );
    }
  }

  &.isDarkTheme {
    color: $dark-theme-text-color;
    background: #000;

    &.isDisableBackgroundGradient {
      background: $dark-theme-bg;
    }

    &:before {
      content: '';
      opacity: 1;
      background: linear-gradient(
        180deg,
        mat-css-vars.mat-css-color(800, 0.35) 0%,
        mat-css-vars.mat-css-color(900, 0.1) 140px,
        mat-css-vars.mat-css-color(900, 0.02) 100%
      );
    }
  }

  // also hide material dialogs while import is in progress
  &.isDataImportInProgress {
    .cdk-overlay-container {
      display: none !important;
    }
  }
}

.page-wrapper {
  padding: $s $s $s * 9;
  @include mq(xs) {
    padding: $s 2 * $s $s * 9;
  }
}

.component-wrapper {
  max-width: $component-max-width;
  margin: auto;
}

.task-list-wrapper {
  padding: 0 0 $s * 7;
  // for a little bit of extra space for 800 width
  max-width: #{$component-max-width - 40};
  margin: auto;

  @include mq(xxs) {
    padding: 0 $s * 1 $s * 7;
  }

  @include mq(xs) {
    padding: 0 $s * 1.5 $s * 7;
  }

  @include mq(sm) {
    padding: 0 3 * $s $s * 7;
    max-width: $component-max-width;
  }

  @include mq(lg) {
    padding: 0 3 * $s $s * 7;
    max-width: $component-max-width + 100;
  }

  improvement-banner + & {
    padding-top: 0;
  }
}

blockquote {
  border-left: 4px solid rgba(var(--palette-accent-500), 1);
  margin: 20px 0;
  padding: 1px 20px;
}

a[href] {
  color: $c-accent;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

// make images responsive per default
img {
  max-width: 100%;
  height: auto;
  display: block;
}

// scrollbars
* {
  --scrollbar-width: thin;
  --scrollbar-width-legacy: 4px;
  --scrollbar-color-thumb: #888;
  --scrollbar-color-track: #f1f1f1;

  .isDarkTheme & {
    --scrollbar-color-thumb: #333;
    --scrollbar-color-track: #222;
  }

  /* Modern browsers with `scrollbar-*` support */
  @supports (scrollbar-width: auto) {
    & {
      scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
      scrollbar-width: var(--scrollbar-width);
    }
  }

  /* Legacy browsers with `::-webkit-scrollbar-*` support */
  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-color-thumb);
    }
    &::-webkit-scrollbar-track {
      background: var(--scrollbar-color-track);
    }
    &::-webkit-scrollbar {
      max-width: var(--scrollbar-width-legacy);
      max-height: var(--scrollbar-width-legacy);
    }
  }
}
